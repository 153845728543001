import React from 'react';
import { Link } from "gatsby";

import RepairBannerImg from "../../assets/images/repair-banner-image/yerinde-takas-esyalar.png";
import RepairShape1 from "../../assets/images/repair-banner-image/repair-shape1.png";
import RepairShape2 from "../../assets/images/repair-banner-image/repair-shape2.png";
import RepairShapeCrcle from "../../assets/images/repair-banner-image/repair-shape-circle.png";

const MainBanner = () => {
    return (
        <div className="repair-main-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="repair-banner-content">
                            <h1>Adresinize Teslim TAKAS</h1>
                            <h5 style={{color:"grey"}}>Yeni eşyalara ücret ödemek yerine takas yaparak tasarruf edin</h5>

                            <h5 style={{color:"grey"}}>Kullanmadığınız sıfır ikinci el veya bozuk eşyalarınızı adresinize gelerek değiştiriyoruz</h5>

                            <Link to="/takas-urunleri" className="btn btn-secondary">
                                Ürünleri İncele Fırsatı Değerlendir
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="repair-banner-image">
                            <img 
                                src={RepairBannerImg} 
                                alt="image" 
                            />
 
                            <img 
                                src={RepairShape1} 
                                className="animate__animated animate__zoomIn animate__delay-0.6s" 
                                alt="image" 
                            /> 
                            
                            <img 
                                src={RepairShape2} 
                                className="animate__animated animate__fadeInLeft animate__delay-0.6s" 
                                alt="image" 
                            />
                    
                            <img 
                                src={RepairShapeCrcle} 
                                className="rotateme" 
                                alt="image" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainBanner;
